export default class CloseCycleCountAndAdjustInventoryDTO {
    public reportId: number;

    public adjustments: Array<CloseCycleCountItemAdjustmentDTO>;

    constructor(reportId: number, adjustments: Array<CloseCycleCountItemAdjustmentDTO>) {
        this.reportId = reportId;
        this.adjustments = adjustments;
    }
}

export class CloseCycleCountItemAdjustmentDTO {
    public itemId: number;

    public adjustmentQuantity?: number;

    public newCount?: number;

    public inventoryCategoryId: number;

    constructor(init: { itemId: number, adjustment?: number, count?: number, inventoryCategoryId: number }) {
        this.itemId = init.itemId;
        if (init.adjustment) this.adjustmentQuantity = init.adjustment;
        if (init.count) this.newCount = init.count;
        this.inventoryCategoryId = init.inventoryCategoryId;
    }
}
