
import {
    computed, defineComponent, PropType,
} from 'vue';
import CycleCountReport from '@/domain/cyclecount/CycleCountReport';
import CycleCountItem from '@/domain/cyclecount/CycleCountItem';
import BTable, { BTableField } from '@/components/bootstrap-library/table/BTable/BTable.vue';
import BFormInput from '@/components/bootstrap-library/BFormInput.vue';
import { getTitleCaseTranslation } from '@/services/TranslationService';
import CycleCountItemTable from '@/domain/cyclecount/CycleCountItemTable';
import BRow from '@/components/bootstrap-library/BRow.vue';
import CycleCountItemTableRow from '@/domain/cyclecount/CycleCountItemTableRow';

type TableData = {
    itemId: number;
    itemName: string;
    newCounts?: Array<number | undefined>;
    dateCounted?: string;
    cycleCountItemRow: CycleCountItemTableRow;
    systemCount?: number;
    inventoryAdjustment?: number;
    count?: number;
    underLoadPieces?: number;
    category?: string;
};

export default defineComponent({
    name: 'cycle-count-report-approve',
    components: {
        BRow,
        BFormInput,
        BTable,
    },
    props: {
        report: {
            type: Object as PropType<CycleCountReport>,
            required: true,
        },
        disableAdjustments: Boolean,
    },
    setup(props) {
        function mapItemsToTableData(cycleCountItemTable: CycleCountItemTable): Array<TableData> {
            return cycleCountItemTable.cycleCountItemTableRows.map((i) => ({
                itemId: i.item?.id ?? i.plannedPart?.id ?? 0,
                itemName: i.item?.name ?? i.plannedPart?.part.description ?? '',
                newCounts: i.cycleCountItems.map((cci) => (cci.underLoadPieces !== undefined && cci.count !== undefined ? Math.abs(cci.count) + Math.abs(cci.underLoadPieces) : cci.count)),
                cycleCountItemRow: i,
            }));
        }

        const tableData = computed((): Array<TableData> => mapItemsToTableData(new CycleCountItemTable(props.report.cycleCountItems)));

        const fields: Array<BTableField<TableData>> = [
            { key: 'itemId', label: getTitleCaseTranslation('core.domain.containerNumber'), width: '140px' },
            { key: 'itemName', label: getTitleCaseTranslation('core.domain.containerName') },
            { key: 'dateCounted', label: getTitleCaseTranslation('core.domain.dateCounted') },
            { key: 'category', label: getTitleCaseTranslation('core.domain.category'), width: '10%' },
            {
                key: 'count',
                label: getTitleCaseTranslation('core.domain.loosePiecesShort'),
                ignoreSort: true,
                searchable: false,
            },
            {
                key: 'underLoadPieces',
                label: getTitleCaseTranslation('core.domain.underLoadPiecesShort'),
                ignoreSort: true,
                searchable: false,
            },
            {
                key: 'newCounts',
                label: getTitleCaseTranslation('core.domain.totalCount'),
                ignoreSort: true,
                searchable: false,
            },
            {
                key: 'systemCount',
                ignoreSort: true,
                searchable: false,
                label: getTitleCaseTranslation('core.domain.systemCount'),
            },
            {
                key: 'inventoryAdjustment',
                ignoreSort: true,
                searchable: false,
                label: getTitleCaseTranslation('core.domain.adjustmentQuantity'),
            },
        ];

        function updateAdjustmentQuantity(value: number | undefined, item: CycleCountItem) {
            if (value !== undefined) {
                item.setInventoryAdjustment(value);
                if (item.systemCount !== undefined) {
                    item.setInventoryAdjustment(Math.abs(value) - Math.abs(item.systemCount));
                }
            }
        }

        return {
            tableData,
            fields,
            updateAdjustmentQuantity,
        };
    },
});
